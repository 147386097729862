// @flow

import * as React from 'react'
import styled from 'styled-components'
import StyledModal, { ModalProvider } from 'styled-react-modal'
import CrossWhiteSvg from '../Icons/CrossWhiteSvg'
import { Colors } from '../Colors'
import { RobotoBold } from '../Typography/Fonts'
import BluredModalBackground from './BluredModalBackground'
import { device } from '../Devices'

const MODAL_TOP_MARGIN_IN_PIXELS = '21px'
const MODAL_BOTTOM_MARGIN_IN_PIXELS = '21px'

const ModalContainer = StyledModal.styled`
  position: relative;
  display: flex;
  overflow: auto;
  background-color: ${Colors.background};
  border-radius: ${({ fullScreenOnlyMobile, fullScreen }) =>
    fullScreenOnlyMobile | fullScreen ? 0 : '8px'};
  box-shadow: 0 0 8px 0 ${Colors.black50};
  width: ${({ fullScreenOnlyMobile, fullScreen }) =>
    fullScreenOnlyMobile | fullScreen ? '100%' : 'min-content'};
  max-height: ${({ fullScreenOnlyMobile, fullScreen }) =>
    fullScreenOnlyMobile | fullScreen
      ? '100%'
      : `calc(100% - ${MODAL_TOP_MARGIN_IN_PIXELS} - ${MODAL_BOTTOM_MARGIN_IN_PIXELS})`};
  height: ${({ fullScreenOnlyMobile, fullScreen }) =>
    fullScreenOnlyMobile | fullScreen ? '100%' : 'max-content'};
 
  margin: ${({ fullScreenOnlyMobile, fullScreen }) =>
    fullScreenOnlyMobile | fullScreen
      ? 0
      : `${MODAL_TOP_MARGIN_IN_PIXELS} 15px ${MODAL_BOTTOM_MARGIN_IN_PIXELS} 15px`};

  @media ${device.laptop} {
    border-radius: ${({ fullScreen }) => (fullScreen ? 0 : '8px')};
    width: ${({ fullScreen }) => (fullScreen ? '100%' : 'min-content')};
    height: ${({ fullScreen }) => (fullScreen ? '100%' : 'max-content')};
    max-height: ${({ fullScreen }) =>
      fullScreen
        ? '100%'
        : `calc(100% - ${MODAL_TOP_MARGIN_IN_PIXELS} - ${MODAL_BOTTOM_MARGIN_IN_PIXELS})`};
    margin: ${({ fullScreen }) =>
      fullScreen
        ? 0
        : `${MODAL_TOP_MARGIN_IN_PIXELS} 15px ${MODAL_BOTTOM_MARGIN_IN_PIXELS} 15px`};
  }
`

export const ModalTitle: any = styled.h1`
  margin-top: 0;
  margin-bottom: 20px;
  font-family: ${RobotoBold.fontFamily};
  font-size: 36px;
  font-weight: ${RobotoBold.weight};
  line-height: 1;
  text-align: center;
  color: ${Colors.white100};
`

export const ModalDefaultContent: any = styled.div`
  margin: auto;
  padding: 48px 30px;
  color: ${Colors.white50};
  width: 360px;
`

const CloseButton = styled.button`
  position: fixed;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;

  &:focus {
    outline: none;
  }
`

const WrapperClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
`

type Props = {|
  isOpen: boolean,
  onRequestClose?: () => void,
  children: React.Node,
  fullScreen?: boolean,
  fullScreenOnlyMobile?: boolean,
  closable?: boolean,
|}

type State = {|
  opacity: number,
|}

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      opacity: 0,
    }
  }

  afterOpen = () => {
    setTimeout(() => {
      this.setState({ opacity: 1 })
    })
  }

  beforeClose = (): Promise<void> => {
    return new Promise(resolve => {
      this.setState({ opacity: 0 })
      setTimeout(resolve, 300)
    })
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      children,
      fullScreen,
      fullScreenOnlyMobile,
      closable,
    } = this.props
    const { opacity } = this.state

    return (
      <ModalProvider backgroundComponent={BluredModalBackground}>
        <ModalContainer
          isOpen={isOpen}
          fullScreen={fullScreen}
          fullScreenOnlyMobile={fullScreenOnlyMobile}
          afterOpen={this.afterOpen}
          beforeClose={this.beforeClose}
          onBackgroundClick={() => onRequestClose && onRequestClose()}
          onEscapeKeydown={() => onRequestClose && onRequestClose()}
          backgroundProps={{ opacity, fullScreen, fullScreenOnlyMobile }}
        >
          {onRequestClose && closable && (
            <WrapperClose>
              <CloseButton onClick={() => onRequestClose()} ariaLabel="Fermer">
                <CrossWhiteSvg />
              </CloseButton>
            </WrapperClose>
          )}

          {children}
        </ModalContainer>
      </ModalProvider>
    )
  }
}

export default (Modal: React.ComponentType<Props>)
