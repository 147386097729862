// @flow

export const Colors = {
  // Deprecated colors,
  greyRegular: '#c2c2c2', // TODO: to be deleted
  greyBold: '#8e8e8e', // TODO: to be deleted
  blackRegular: '#3d3d3d', // TODO: to be deleted
  blackBold: '#1f1f1f', // TODO: to be deleted

  // New colors

  green: '#2ec973',
  greenLight: '#d2f9e3',

  red: '#ff5858',

  black100: '#000000',
  black75: 'rgba(0, 0, 0, 0.75)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black35: 'rgba(0, 0, 0, 0.35)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black10: 'rgba(0, 0, 0, 0.1)',
  carbon100: '#1f1f1f',

  grey: '#8e8e8e',

  white: '#f6f6f6',
  white100: '#ffffff',
  white85: 'rgba(255, 255, 255, 0.85)',
  white75: 'rgba(255, 255, 255, 0.75)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white5: 'rgba(255, 255, 255, 0.05)',

  typo1: '#df4cff',
  typo3: '#8daf05',
  typo2: '#fdcc02',
  typo4: '#0099af',

  background: '#2f2f2f',
  inputBackground: '#2b2b2b', // TODO: repasse JM
  modalBackground: '#272727',
}
